import type { OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Input,
} from '@angular/core';
import type { Badge, MembershipBadgeType } from '@freelancer/ui/badge';
import {
  BadgeSize,
  BadgeType,
  CorporateBadgeType,
  PreferredFreelancerBadgeType,
  RecruiterBadgeType,
  RisingStarBadgeType,
  StaffBadgeType,
  UserBadgeType,
  VerifiedBadgeType,
} from '@freelancer/ui/badge';
import {
  HeadingColor,
  HeadingType,
  HeadingWeight,
} from '@freelancer/ui/heading';
import { LinkColor, QueryParams } from '@freelancer/ui/link';
import { FontColor, FontType, FontWeight, TextSize } from '@freelancer/ui/text';
import {
  UsernameBadges,
  UsernameColor,
  UsernameMembershipPackage,
  UsernameSize,
} from './username.types';

@Component({
  selector: 'fl-username',
  template: `
    <ng-container *ngIf="!isLink">
      <ng-container *ngIf="!isHeading">
        <div
          class="Username-row"
          [attr.data-size]="size"
          [attr.data-size-tablet]="sizeTablet"
          [attr.data-size-desktop]="sizeDesktop"
          [attr.data-truncate]="truncateText"
        >
          <fl-flag
            *ngIf="country"
            class="Username-flag"
            [attr.data-size]="size"
            [attr.data-size-tablet]="sizeTablet"
            [attr.data-size-desktop]="sizeDesktop"
            [countryCode]="country"
            [flMarginRight]="'xxsmall'"
          ></fl-flag>
          <fl-text
            *ngIf="displayName"
            class="Username-displayName"
            [attr.data-truncate]="truncateText"
            [color]="displayNameFontColor"
            [flHideMobile]="hideDisplayNameOnMobile"
            [flMarginRight]="compact ? 'none' : 'xxxsmall'"
            [flMarginRightTablet]="compactTablet ? 'none' : 'xxxsmall'"
            [fontType]="FontType.SPAN"
            [size]="usernameSize"
            [sizeTablet]="usernameSizeTablet"
            [sizeDesktop]="usernameSizeDesktop"
            [sizeDesktopLarge]="usernameSizeDesktopLarge"
            [sizeDesktopXLarge]="usernameSizeDesktopXLarge"
            [sizeDesktopXXLarge]="usernameSizeDesktopXXLarge"
            [weight]="FontWeight.INHERIT"
          >
            {{ displayName }}
          </fl-text>
        </div>
        <div
          class="Username-row"
          [attr.data-truncate]="truncateText"
          [attr.data-size]="size"
          [attr.data-size-tablet]="sizeTablet"
          [attr.data-size-desktop]="sizeDesktop"
        >
          <fl-text
            *ngIf="showUsernameTag"
            class="Username-userId"
            [attr.data-truncate]="truncateText"
            [attr.data-size]="size"
            [attr.data-size-tablet]="sizeTablet"
            [attr.data-size-desktop]="sizeDesktop"
            [color]="usernameFontColor"
            [fontType]="FontType.SPAN"
            [size]="userHandleSize"
            [sizeTablet]="userHandleSizeTablet"
            [sizeDesktop]="userHandleSizeDesktop"
            [sizeDesktopLarge]="userHandleSizeDesktopLarge"
            [sizeDesktopXLarge]="userHandleSizeDesktopXLarge"
            [sizeDesktopXXLarge]="userHandleSizeDesktopXXLarge"
            [weight]="
              displayName || !isBold ? FontWeight.INHERIT : FontWeight.BOLD
            "
          >
            <ng-container *ngTemplateOutlet="userhandle"></ng-container>
          </fl-text>
          <ng-container
            *ngTemplateOutlet="userBadges; context: { badges: badges }"
          ></ng-container>
        </div>
      </ng-container>

      <ng-container *ngIf="isHeading">
        <div
          class="Username-row"
          [attr.data-size]="size"
          [attr.data-size-tablet]="sizeTablet"
          [attr.data-size-desktop]="sizeDesktop"
        >
          <fl-flag
            *ngIf="country"
            class="Username-flag"
            [attr.data-size]="size"
            [attr.data-size-tablet]="sizeTablet"
            [attr.data-size-desktop]="sizeDesktop"
            [countryCode]="country"
            [flMarginRight]="'xxsmall'"
          ></fl-flag>
          <fl-heading
            *ngIf="displayName"
            class="Username-displayName"
            [attr.data-truncate]="truncateText"
            [attr.data-size-tablet]="sizeTablet"
            [attr.data-size-desktop]="sizeDesktop"
            [color]="displayNameHeadingColor"
            [flHideMobile]="hideDisplayNameOnMobile"
            [flMarginRight]="compact ? 'none' : 'xxxsmall'"
            [flMarginRightTablet]="
              compactTablet
                ? 'none'
                : size === UsernameSize.PROFILE_SUMMARY_MOBILE
                ? 'xsmall'
                : 'xxxsmall'
            "
            [headingType]="HeadingType.H3"
            [size]="usernameSize"
            [sizeTablet]="usernameSizeTablet"
            [sizeDesktop]="usernameSizeDesktop"
            [sizeDesktopLarge]="usernameSizeDesktopLarge"
            [sizeDesktopXLarge]="usernameSizeDesktopXLarge"
            [sizeDesktopXXLarge]="usernameSizeDesktopXXLarge"
            [weight]="HeadingWeight.INHERIT"
          >
            {{ displayName }}
          </fl-heading>
        </div>
        <div
          class="Username-row"
          [attr.data-size]="size"
          [attr.data-size-tablet]="sizeTablet"
          [attr.data-size-desktop]="sizeDesktop"
        >
          <fl-heading
            *ngIf="showUsernameTag"
            class="Username-userId Username-userId-heading"
            [attr.data-compact]="compact"
            [attr.data-compact-tablet]="compactTablet"
            [attr.data-truncate]="truncateText"
            [attr.data-size]="size"
            [attr.data-size-tablet]="sizeTablet"
            [attr.data-size-desktop]="sizeDesktop"
            [color]="usernameHeadingColor"
            [headingType]="HeadingType.H3"
            [size]="userHandleSize"
            [sizeTablet]="userHandleSizeTablet"
            [sizeDesktop]="userHandleSizeDesktop"
            [sizeDesktopLarge]="userHandleSizeDesktopLarge"
            [sizeDesktopXLarge]="userHandleSizeDesktopXLarge"
            [sizeDesktopXXLarge]="userHandleSizeDesktopXXLarge"
            [weight]="
              displayName || !isBold
                ? HeadingWeight.INHERIT
                : HeadingWeight.BOLD
            "
          >
            <ng-container *ngTemplateOutlet="userhandle"></ng-container>
          </fl-heading>
          <ng-container
            *ngTemplateOutlet="userBadges; context: { badges: badges }"
          ></ng-container>
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngIf="isLink">
      <div
        class="Username-row"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-truncate]="truncateText"
      >
        <fl-flag
          *ngIf="country"
          class="Username-flag"
          [attr.data-size]="size"
          [attr.data-size-tablet]="sizeTablet"
          [attr.data-size-desktop]="sizeDesktop"
          [countryCode]="country"
          [flMarginRight]="'xxsmall'"
        ></fl-flag>
        <fl-text
          *ngIf="displayName"
          class="Username-displayName"
          [attr.data-truncate]="truncateText"
          [color]="displayNameFontColor"
          [flHideMobile]="hideDisplayNameOnMobile"
          [flMarginRight]="compact ? 'none' : 'xxxsmall'"
          [flMarginRightTablet]="compactTablet ? 'none' : 'xxxsmall'"
          [fontType]="FontType.SPAN"
          [size]="usernameSize"
          [sizeTablet]="usernameSizeTablet"
          [sizeDesktop]="usernameSizeDesktop"
          [sizeDesktopLarge]="usernameSizeDesktopLarge"
          [sizeDesktopXLarge]="usernameSizeDesktopXLarge"
          [sizeDesktopXXLarge]="usernameSizeDesktopXXLarge"
          [weight]="FontWeight.INHERIT"
        >
          {{ displayName }}
        </fl-text>
      </div>
      <div
        class="Username-row"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
        [attr.data-truncate]="truncateText"
      >
        <fl-text
          class="Username-userId"
          [color]="usernameFontColor"
          [fontType]="FontType.SPAN"
          [weight]="
            displayName || !isBold ? FontWeight.INHERIT : FontWeight.BOLD
          "
          [attr.data-truncate]="truncateText"
        >
          <fl-link
            *ngIf="showUsernameTag"
            [attr.data-size]="size"
            [attr.data-size-tablet]="sizeTablet"
            [attr.data-size-desktop]="sizeDesktop"
            [color]="LinkColor.INHERIT"
            [isAbsoluteUrl]="true"
            [link]="link"
            [newTab]="newTab"
            [size]="userHandleSize"
            [sizeTablet]="userHandleSizeTablet"
            [sizeDesktop]="userHandleSizeDesktop"
            [sizeDesktopLarge]="userHandleSizeDesktopLarge"
            [sizeDesktopXLarge]="userHandleSizeDesktopXLarge"
            [sizeDesktopXXLarge]="userHandleSizeDesktopXXLarge"
            [queryParams]="queryParams"
          >
            <ng-container *ngTemplateOutlet="userhandle"></ng-container>
          </fl-link>
        </fl-text>
        <ng-container
          *ngTemplateOutlet="userBadges; context: { badges: badges }"
        ></ng-container>
      </div>
    </ng-container>

    <ng-template #userhandle>@{{ username }}</ng-template>
    <ng-template
      #userBadges
      let-badges="badges"
    >
      <div
        *ngIf="badges || membershipPackage"
        class="Username-badges"
        [attr.data-size]="size"
        [attr.data-size-tablet]="sizeTablet"
        [attr.data-size-desktop]="sizeDesktop"
      >
        <fl-badge
          *ngIf="
            membershipPackage &&
            membershipPackage.name !== 'free' &&
            membershipPackage.badgeType
          "
          class="Username-badge"
          matTooltip="{{ membershipPackage.name | titlecase }} Membership"
          i18n-matTooltip="UserMembershipBadgeTooltipMessage"
          i18n-aria-label="Membership package badge"
          [ariaLabel]="
            (membershipPackage.name | titlecase) + ' Membership badge'
          "
          [badge]="getMembershipBadge(membershipPackage.badgeType)"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.verified"
          class="Username-badge"
          matTooltip="Verified Freelancer"
          i18n-matTooltip="Verified Freelancer badge tooltip message"
          aria-label="Verified by Freelancer badge"
          i18n-aria-label="Verified by Freelancer badge"
          [badge]="verifiedBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.preferred_freelancer"
          class="Username-badge"
          matTooltip="Preferred Freelancer"
          i18n-matTooltip="Preferred Freelancer badge tooltip message"
          aria-label="Preferred Freelancer badge"
          i18n-aria-label="Preferred Freelancer badge"
          [badge]="preferredFreelancerBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.rising_star"
          class="Username-badge"
          matTooltip="Rising Star"
          i18n-matTooltip="Rising Star badge tooltip message"
          aria-label="Rising Star badge"
          i18n-aria-label="Rising Star badge"
          [badge]="risingStarBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.corporate"
          class="Username-badge"
          matTooltip="Corporate Freelancer"
          i18n-matTooltip="Corporate Freelancer badge tooltip message"
          aria-label="Corporate Freelancer badge"
          i18n-aria-label="Corporate Freelancer badge"
          [badge]="corporateBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.shareholder"
          class="Username-badge"
          matTooltip="Foundation Shareholder"
          i18n-matTooltip="Foundation Shareholder badge tooltip message"
          aria-label="Foundation Shareholder badge"
          i18n-aria-label="Foundation Shareholder badge"
          [badge]="shareholderBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.do_gooder"
          class="Username-badge"
          aria-label="Do Gooder badge"
          i18n-aria-label="Do Gooder badge"
          matTooltip="Do Gooder"
          i18n-matTooltip="Tooltip for a Do Gooder badge"
          [badge]="doGooderBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.freelancer_staff"
          class="Username-badge"
          matTooltip="Freelancer Staff"
          i18n-matTooltip="Freelancer Staff badge tooltip message"
          aria-label="Freelancer Staff badge"
          i18n-aria-label="Freelancer Staff badge"
          [badge]="freelancerStaffBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>

        <fl-badge
          *ngIf="badges?.freelancer_recruiter"
          class="Username-badge"
          matTooltip="Freelancer Recruiter"
          i18n-matTooltip="Freelancer Recruiter badge tooltip message"
          aria-label="Freelancer Recruiter badge"
          i18n-aria-label="Freelancer Recruiter badge"
          [badge]="freelancerRecruiterBadge"
          [size]="badgeSize"
          [sizeTablet]="badgeSizeTablet"
          [sizeDesktop]="badgeSizeDesktop"
          [sizeDesktopLarge]="badgeSizeDesktopLarge"
          [sizeDesktopXLarge]="badgeSizeDesktopXLarge"
          [sizeDesktopXXLarge]="badgeSizeDesktopXXLarge"
        ></fl-badge>
      </div>
    </ng-template>
  `,
  styleUrls: ['./username.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UsernameComponent implements OnChanges, OnInit {
  BadgeSize = BadgeSize;
  FontColor = FontColor;
  TextSize = TextSize;
  FontType = FontType;
  FontWeight = FontWeight;
  HeadingType = HeadingType;
  HeadingWeight = HeadingWeight;
  LinkColor = LinkColor;
  UsernameSize = UsernameSize;
  BadgeType = BadgeType;

  badgeSize = BadgeSize.SMALL;
  badgeSizeTablet?: BadgeSize | undefined;
  badgeSizeDesktop?: BadgeSize | undefined;
  badgeSizeDesktopLarge?: BadgeSize | undefined;
  badgeSizeDesktopXLarge?: BadgeSize | undefined;
  badgeSizeDesktopXXLarge?: BadgeSize | undefined;
  displayNameFontColor: FontColor;
  displayNameHeadingColor: HeadingColor;
  usernameFontColor: FontColor;
  usernameHeadingColor: HeadingColor;

  userHandleSize: TextSize;
  userHandleSizeTablet?: TextSize | undefined;
  userHandleSizeDesktop?: TextSize | undefined;
  userHandleSizeDesktopLarge?: TextSize | undefined;
  userHandleSizeDesktopXLarge?: TextSize | undefined;
  // TODO: T298322 This should ONLY used for the username in the profile summary and will be cleaned up in T298322
  userHandleSizeDesktopXXLarge?: TextSize | undefined;
  usernameSize: TextSize;
  usernameSizeTablet?: TextSize | undefined;
  usernameSizeDesktop?: TextSize | undefined;
  usernameSizeDesktopLarge?: TextSize | undefined;
  usernameSizeDesktopXLarge?: TextSize | undefined;
  // TODO: T298322 This should ONLY used for the username in the profile summary and will be cleaned up in T298322
  usernameSizeDesktopXXLarge?: TextSize | undefined;
  isHeading = false;

  verifiedBadge: Badge = {
    type: BadgeType.VERIFIED,
    badge: VerifiedBadgeType.VERIFIED,
  };

  preferredFreelancerBadge: Badge = {
    type: BadgeType.PREFERRED_FREELANCER,
    badge: PreferredFreelancerBadgeType.PREFERRED_FREELANCER,
  };

  corporateBadge: Badge = {
    type: BadgeType.CORPORATE,
    badge: CorporateBadgeType.CORPORATE,
  };

  shareholderBadge: Badge = {
    type: BadgeType.USER,
    badge: UserBadgeType.FOUNDATION_SHAREHOLDER,
  };

  risingStarBadge: Badge = {
    type: BadgeType.RISING_STAR,
    badge: RisingStarBadgeType.RISING_STAR,
  };

  doGooderBadge: Badge = {
    type: BadgeType.USER,
    badge: UserBadgeType.DO_GOODER,
  };

  freelancerStaffBadge: Badge = {
    type: BadgeType.STAFF,
    badge: StaffBadgeType.FREELANCER_STAFF,
  };

  freelancerRecruiterBadge: Badge = {
    type: BadgeType.RECRUITER,
    badge: RecruiterBadgeType.FREELANCER_RECRUITER,
  };

  @Input() color = UsernameColor.FOREGROUND;
  @Input() displayName?: string;
  @Input() hideDisplayNameOnMobile = false;
  @Input() username: string;
  @Input() isBold = true;

  /** We don't advise the usage of country flags with new implementations of
  the username component. Please seek approval from management for permission
  to implement a country flag within the username component. */
  @Input() country?: string;
  @Input() queryParams?: QueryParams;
  @Input() link?: string;
  get isLink(): boolean {
    return this.link !== undefined ? this.link.length > 0 : false;
  }
  @Input() newTab?: boolean;
  @Input() truncateText?: boolean;

  @Input() size = UsernameSize.MID;
  @Input() sizeTablet?: UsernameSize;
  @Input() sizeDesktop?: UsernameSize;
  @Input() sizeDesktopLarge?: UsernameSize;
  @Input() sizeDesktopXLarge?: UsernameSize;
  @Input() sizeDesktopXXLarge?: UsernameSize;

  @HostBinding('attr.data-compact')
  @Input()
  compact = false;

  @HostBinding('attr.data-compact-tablet')
  @Input()
  compactTablet?: boolean;

  /** We recommend to not use this option, username should always be shown. */
  @Input() showUsernameTag? = true;

  @Input() badges?: UsernameBadges;
  @Input() membershipPackage?: UsernameMembershipPackage;

  ngOnInit(): void {
    this.initializeUsername();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('color' in changes) {
      this.setUsernameColor();
    }

    if ('size' in changes) {
      this.getUsernameSize();
      this.getBadgeSize();
    }
  }

  initializeUsername(): void {
    this.setUsernameColor();
    this.getUsernameSize();
  }

  setUsernameColor(): void {
    switch (this.color) {
      case UsernameColor.FOREGROUND:
        this.displayNameFontColor = FontColor.FOREGROUND;
        this.displayNameHeadingColor = HeadingColor.FOREGROUND;
        this.usernameFontColor = FontColor.FOREGROUND;
        this.usernameHeadingColor = HeadingColor.FOREGROUND;
        break;
      case UsernameColor.LIGHT:
        this.displayNameFontColor = FontColor.LIGHT;
        this.displayNameHeadingColor = HeadingColor.LIGHT;
        this.usernameFontColor = FontColor.LIGHT;
        this.usernameHeadingColor = HeadingColor.LIGHT;
        break;
      case UsernameColor.INHERIT:
        this.displayNameFontColor = FontColor.INHERIT;
        this.displayNameHeadingColor = HeadingColor.INHERIT;
        this.usernameFontColor = FontColor.INHERIT;
        this.usernameHeadingColor = HeadingColor.INHERIT;
        break;
      default:
        this.displayNameFontColor = FontColor.DARK;
        this.displayNameHeadingColor = HeadingColor.DARK;
        this.usernameFontColor = FontColor.DARK;
        this.usernameHeadingColor = HeadingColor.DARK;
    }
  }

  generateBadgeSize(size: UsernameSize | undefined): BadgeSize | undefined {
    switch (size) {
      case UsernameSize.PROFILE_SUMMARY_MOBILE:
      case UsernameSize.XXLARGE:
      case UsernameSize.XLARGE:
        return BadgeSize.MID;
      case UsernameSize.LARGE:
      case UsernameSize.MID:
      case UsernameSize.SMALL:
        return BadgeSize.SMALL;
      case UsernameSize.PROFILE_SUMMARY:
        return BadgeSize.PROFILE;
      default:
        return undefined;
    }
  }

  getBadgeSize(): void {
    // Set default size to BadgeSize.SMALL
    this.badgeSize = this.generateBadgeSize(this.size) || BadgeSize.SMALL;
    this.badgeSizeTablet = this.generateBadgeSize(this.sizeTablet);
    this.badgeSizeDesktop = this.generateBadgeSize(this.sizeDesktop);
    this.badgeSizeDesktopLarge = this.generateBadgeSize(this.sizeDesktopLarge);
    this.badgeSizeDesktopXLarge = this.generateBadgeSize(
      this.sizeDesktopXLarge,
    );
    this.badgeSizeDesktopXXLarge = this.generateBadgeSize(
      this.sizeDesktopXXLarge,
    );
  }

  getMembershipBadge(badge: MembershipBadgeType): Badge {
    return {
      type: BadgeType.MEMBERSHIP,
      badge,
    };
  }

  generateUsernameSize(size: UsernameSize | undefined): TextSize | undefined {
    switch (size) {
      case UsernameSize.XXLARGE:
        return TextSize.LARGE;
      case UsernameSize.XLARGE:
        return TextSize.MID;
      case UsernameSize.LARGE:
        return TextSize.SMALL;
      case UsernameSize.MID:
        return TextSize.XSMALL;
      case UsernameSize.SMALL:
        return TextSize.XXSMALL;
      case UsernameSize.PROFILE_SUMMARY_MOBILE:
        return TextSize.XLARGE;
      case UsernameSize.PROFILE_SUMMARY:
        return TextSize.XXXLARGE;
      default:
        return undefined;
    }
  }

  isUsernameSizeHeading(size: UsernameSize): boolean {
    // Heading is true only for UsernameSize.PROFILE_SUMMARY, Username.PROFILE_SUMMARY_MOBILE, Username.XXLARGE, and Username.XLARGE
    return [
      UsernameSize.PROFILE_SUMMARY,
      UsernameSize.PROFILE_SUMMARY_MOBILE,
      UsernameSize.XXLARGE,
      UsernameSize.XLARGE,
    ].includes(size);
  }

  getUsernameSize(): void {
    // Set default size to TextSize.XSMALL
    this.usernameSize = this.generateUsernameSize(this.size) || TextSize.XSMALL;
    this.usernameSizeTablet = this.generateUsernameSize(this.sizeTablet);
    this.usernameSizeDesktop = this.generateUsernameSize(this.sizeDesktop);
    this.usernameSizeDesktopLarge = this.generateUsernameSize(
      this.sizeDesktopLarge,
    );
    this.usernameSizeDesktopXLarge = this.generateUsernameSize(
      this.sizeDesktopXLarge,
    );
    this.usernameSizeDesktopXXLarge =
      this.sizeTablet === UsernameSize.PROFILE_SUMMARY
        ? TextSize.MARKETING_MEGA
        : this.generateUsernameSize(this.sizeDesktopXXLarge);
    this.isHeading = this.isUsernameSizeHeading(this.size);
    this.userHandleSize = this.usernameSize;
    if (this.size === UsernameSize.PROFILE_SUMMARY) {
      this.userHandleSize = TextSize.XXLARGE;
    } else if (this.size === UsernameSize.PROFILE_SUMMARY_MOBILE) {
      this.userHandleSize = TextSize.LARGE;
    }
    this.userHandleSizeTablet = this.usernameSizeTablet;
    if (this.sizeTablet === UsernameSize.PROFILE_SUMMARY) {
      this.userHandleSizeTablet = TextSize.XXLARGE;
    } else if (this.sizeTablet === UsernameSize.PROFILE_SUMMARY_MOBILE) {
      this.userHandleSizeTablet = TextSize.LARGE;
    }
    this.userHandleSizeDesktop = this.usernameSizeDesktop;
    if (this.sizeDesktop === UsernameSize.PROFILE_SUMMARY) {
      this.userHandleSizeDesktop = TextSize.XXLARGE;
    } else if (this.sizeDesktop === UsernameSize.PROFILE_SUMMARY_MOBILE) {
      this.userHandleSizeDesktop = TextSize.LARGE;
    }
    this.userHandleSizeDesktopLarge = this.usernameSizeDesktopLarge;
    this.userHandleSizeDesktopXLarge = this.usernameSizeDesktopXLarge;
    this.userHandleSizeDesktopXXLarge =
      this.sizeTablet === UsernameSize.PROFILE_SUMMARY
        ? TextSize.XXXLARGE
        : this.usernameSizeDesktopXXLarge;
  }

  trackByType(_: number, badge: Badge): BadgeType {
    return badge.type;
  }
}
