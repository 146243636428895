import type {
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  HostBinding,
  Input,
  Output,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { trackByValue } from '@freelancer/ui/helpers';
import { HoverColor, IconColor, IconSize } from '@freelancer/ui/icon';
import { isDefined } from '@freelancer/utils';
import type { Subscription } from 'rxjs';
import {
  RatingColor,
  RatingTextColor,
  RatingTicksColor,
  RatingType,
} from './rating.types';

@Component({
  selector: 'fl-rating',
  template: `
    <fl-bit
      class="RatingContainer"
      [flMarginBottom]="
        control.invalid && control.dirty ? 'xxxsmall' : undefined
      "
    >
      <fl-bit
        class="LayerGroup"
        [attr.tabindex]="!isInteractable ? 0 : undefined"
        [attr.role]="isInteractable ? 'group' : 'img'"
        [attr.aria-disabled]="isInteractable ? control.disabled : undefined"
        [attr.aria-errormessage]="isInteractable ? errorId : undefined"
        [attr.aria-invalid]="isInteractable ? control.invalid : undefined"
        [attr.aria-label]="
          translateRating(control.value | number : ratingValueFormat)
        "
        [attr.data-background-color]="backgroundColor"
        [attr.data-rating]="control.value"
        [flMarginRight]="'xxxsmall'"
        (mouseleave)="setHover(0)"
        (focusout)="setHover(0)"
      >
        <!-- RATING ICONS -->
        <fl-bit
          class="IconLayer"
          *ngIf="!compact"
          [flShowDesktop]="compactTablet"
          [flHideMobile]="compactMobile"
        >
          <ng-container
            *ngFor="
              let item of items;
              index as itemIndex;
              last as isLast;
              trackBy: trackByValue
            "
          >
            <fl-bit
              class="IconContainer IconContainerSvg"
              [ngClass]="{ IconSpacer: !isLast }"
              [attr.data-type]="type"
              [attr.data-ticks-color]="ticksColor"
            >
              <!-- RatingType STARS -->
              <ng-container *ngIf="type === RatingType.STARS">
                <svg
                  width="12"
                  height="24"
                  viewBox="0 0 12 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  class="StarIcon StarIcon-leftHalf"
                  [ngClass]="{
                    IsActive: isDefined(control.value)
                      ? control.value >= item - 0.75
                      : false,
                    IsHover: hoverValue >= item - 0.75,
                    IsInteractable: isInteractable
                  }"
                  [attr.data-size]="size"
                  [attr.data-size-tablet]="sizeTablet"
                  [attr.data-size-desktop]="sizeDesktop"
                  [attr.tabindex]="isInteractable && halfRating ? 0 : -1"
                  [attr.aria-label]="
                    isInteractable && halfRating
                      ? ratingTexts[itemIndex * 2]
                      : undefined
                  "
                  [attr.role]="
                    isInteractable && halfRating ? 'checkbox' : 'presentation'
                  "
                  [attr.focusable]="isInteractable && halfRating"
                  [attr.aria-checked]="
                    isInteractable && halfRating
                      ? isDefined(control.value) && control.value >= item - 0.5
                      : undefined
                  "
                  (click)="setValue(halfRating ? item - 0.5 : item)"
                  (mouseover)="setHover(halfRating ? item - 0.5 : item)"
                  (focusin)="setHover(halfRating ? item - 0.5 : item)"
                >
                  <path
                    d="M12 17.27V2L9.19 8.63L2 9.24L7.46 13.97L5.82 21L12 17.27Z"
                    fill-rule="nonzero"
                  />
                </svg>

                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                  class="StarIcon StarIcon-full"
                  [ngClass]="{
                    IsActive: isDefined(control.value)
                      ? control.value >= item - 0.25
                      : false,
                    IsHover: hoverValue >= item - 0.25,
                    IsInteractable: isInteractable
                  }"
                  [attr.data-size]="size"
                  [attr.data-size-tablet]="sizeTablet"
                  [attr.data-size-desktop]="sizeDesktop"
                  [attr.tabindex]="isInteractable ? 0 : -1"
                  [attr.aria-label]="
                    isInteractable ? ratingTexts[itemIndex * 2 + 1] : undefined
                  "
                  [attr.focusable]="isInteractable"
                  [attr.role]="isInteractable ? 'checkbox' : 'presentation'"
                  [attr.aria-checked]="
                    isInteractable
                      ? isDefined(control.value) && control.value >= item - 0.25
                      : undefined
                  "
                  (click)="setValue(item)"
                  (mouseover)="setHover(item)"
                  (focusin)="setHover(item)"
                >
                  <path
                    d="M9.19 8.63 2 9.24 7.46 13.97 5.82 21 12 17.27 18.18 21 16.54 13.97 22 9.24 14.81 8.63 12 2Z"
                    fill-rule="nonzero"
                  />
                </svg>
              </ng-container>

              <!-- RatingType TICKS -->
              <ng-container *ngIf="type === RatingType.TICKS">
                <fl-icon
                  class="Ticks"
                  [ngClass]="{
                    IsActive: isDefined(control.value)
                      ? control.value >= item
                      : false,
                    IsHover: hoverValue >= item
                  }"
                  [name]="'ui-check-in-circle-v2'"
                  [color]="IconColor.INHERIT"
                  [size]="size"
                  [sizeTablet]="sizeTablet"
                  [sizeDesktop]="sizeDesktop"
                  [label]="
                    isInteractable ? ratingTexts[itemIndex * 2] : undefined
                  "
                  [attr.aria-checked]="
                    isInteractable
                      ? isDefined(control.value) && control.value >= item
                      : undefined
                  "
                  (click)="setValue(item)"
                  (mouseover)="setHover(item)"
                  (focusin)="setHover(item)"
                ></fl-icon>
              </ng-container>
            </fl-bit>
          </ng-container>
        </fl-bit>

        <!-- COMPACT ICON -->
        <fl-bit
          class="IconLayer"
          *ngIf="compact || compactMobile || compactTablet"
          [flHideDesktop]="compactTablet"
          [flShowMobile]="compactMobile"
        >
          <fl-bit
            class="IconContainer"
            [attr.data-type]="type"
            [attr.data-ticks-color]="ticksColor"
          >
            <fl-icon
              class="CompactIcon"
              [class.IsActive]="control.value"
              [name]="
                this.type === RatingType.TICKS
                  ? 'ui-check-in-circle-v2'
                  : 'ui-star-v2'
              "
              [color]="IconColor.INHERIT"
              [size]="size"
              [sizeTablet]="sizeTablet"
              [sizeDesktop]="sizeDesktop"
            ></fl-icon>
          </fl-bit>
        </fl-bit>
      </fl-bit>
      <fl-bit
        *ngIf="
          (!hideValue && type !== RatingType.TICKS) ||
          (type === RatingType.TICKS &&
            (compact || compactMobile || compactTablet))
        "
        class="ValueBlock"
        [flHide]="type === RatingType.TICKS && !compact"
        [flShowDesktop]="type === RatingType.TICKS && !compactTablet"
        [flHideMobile]="type === RatingType.TICKS && !compactMobile"
        [attr.data-icon-size]="size"
        [attr.data-icon-size-tablet]="sizeTablet"
        [attr.data-icon-size-desktop]="sizeDesktop"
        [attr.data-text-color]="textColor"
        [flMarginRight]="isReviewCountSet ? 'xxxsmall' : 'none'"
      >
        {{ itemsCount | number : ratingValueFormat }}
      </fl-bit>
      <fl-bit
        *ngIf="isReviewCountSet"
        class="ReviewCount"
        [attr.data-icon-size]="size"
        [attr.data-icon-size-tablet]="sizeTablet"
        [attr.data-icon-size-desktop]="sizeDesktop"
        [attr.data-text-color]="textColor"
      >
        <ng-container
          *ngIf="reviewTextSingular && reviewTextPlural; else defaultReviewText"
        >
          <ng-container *ngIf="reviewCount === 1">
            ({{ reviewCount }} {{ reviewTextSingular }})
          </ng-container>
          <ng-container *ngIf="reviewCount !== 1">
            ({{ reviewCount }} {{ reviewTextPlural }})
          </ng-container>
        </ng-container>
        <ng-template #defaultReviewText>
          <ng-container
            *ngIf="reviewCount === 1"
            i18n="Single number of review"
          >
            ({{ reviewCount }} review)
          </ng-container>
          <ng-container
            *ngIf="reviewCount !== 1"
            i18n="Number of reviews"
          >
            ({{ reviewCount }} reviews)
          </ng-container>
        </ng-template>
      </fl-bit>
    </fl-bit>
    <fl-validation-error
      [id]="errorId"
      [control]="control"
    ></fl-validation-error>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./rating.component.scss'],
})
export class RatingComponent implements OnDestroy, OnInit, OnChanges {
  HoverColor = HoverColor;
  IconColor = IconColor;
  IconSize = IconSize;
  RatingType = RatingType;

  isDefined = isDefined;
  trackByValue = trackByValue;

  readonly ratingValueFormat = '1.1-1';
  readonly ratingTexts: readonly string[] = [
    this.translateRating(0.5),
    this.translateRating(1),
    this.translateRating(1.5),
    this.translateRating(2),
    this.translateRating(2.5),
    this.translateRating(3),
    this.translateRating(3.5),
    this.translateRating(4),
    this.translateRating(4.5),
    this.translateRating(5),
  ];

  items: number[] = [1, 2, 3, 4, 5];
  hoverValue = 0;
  itemsCount = 0;
  isReviewCountSet: boolean;
  isInteractable: boolean;
  errorId: string;

  private valueChangeSubscription?: Subscription;

  // Accepts `undefined` as a default value, but internally, it
  // should never set `control.value` to `undefined`
  @Input() control: FormControl<number | undefined>;
  @Input() size = IconSize.MID;
  @Input() sizeTablet: IconSize;
  @Input() sizeDesktop: IconSize;
  @Input() halfRating = false;
  @Input() hideValue = false;
  @Input() reviewCount?: number;
  @Input() reviewTextSingular?: string;
  @Input() reviewTextPlural?: string;

  /** Display the component inline with other inline level elements */
  @HostBinding('attr.data-display-inline')
  @Input()
  inline = false;

  @HostBinding('attr.data-read-only')
  @Input()
  readOnly = false;

  @HostBinding('attr.data-icon-color')
  @Input()
  backgroundColor: RatingColor;

  @HostBinding('attr.data-compact')
  @Input()
  compact = false;

  /** Automatically makes the component compact on sizes tablet and lower */
  @HostBinding('attr.data-compact-tablet')
  @Input()
  compactTablet?: boolean;

  /** Automatically makes the component compact on mobile */
  @HostBinding('attr.data-compact-mobile')
  @Input()
  compactMobile?: boolean;

  @HostBinding('attr.data-type')
  @Input()
  type = RatingType.STARS;

  @HostBinding('attr.data-ticks-color')
  @Input()
  ticksColor = RatingTicksColor.PRIMARY;

  @HostBinding('attr.data-text-color')
  @Input()
  textColor: RatingTextColor = 'foreground';

  @Output() hoverValueChange = new EventEmitter<number>();

  constructor(public changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.errorId = `rating-error_${this.generateUniqueId()}`;
    this.itemsCount = this.control.value ?? 0;
    this.valueChangeSubscription = this.control.valueChanges.subscribe(() => {
      this.render();
    });
    this.setInteractableState();
    this.render();
  }

  ngOnDestroy(): void {
    if (this.valueChangeSubscription) {
      this.valueChangeSubscription.unsubscribe();
    }
  }

  setValue(value: number): void {
    if (!this.isInteractable) {
      return;
    }
    this.hoverValue = 0;
    this.control.markAsDirty();
    this.control.setValue(value);
  }

  private render(): void {
    this.itemsCount = (this.hoverValue || this.control.value) ?? 0;
    this.changeDetectorRef.markForCheck();
  }

  setHover(value: number): void {
    if (!this.isInteractable) {
      return;
    }
    this.hoverValue = value;
    this.render();
    this.hoverValueChange.emit(this.hoverValue);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('reviewCount' in changes) {
      this.isReviewCountSet = isDefined(this.reviewCount);
    }

    if ('compact' in changes || 'readOnly' in changes) {
      this.setInteractableState();
    }
  }

  setInteractableState(): void {
    this.isInteractable = !this.readOnly && !this.compact;
  }

  translateRating(rating?: number | string | null): string {
    return isDefined(rating)
      ? $localize`Rating: ${rating} out of 5`
      : $localize`Unrated`;
  }

  /**
   * TODO: T281398 - Remove this once we have a proper unique id generator
   */
  private generateUniqueId(): string {
    return Math.random().toString(36).substring(2, 6);
  }
}
